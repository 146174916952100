<script lang="ts">
	import ZeldaWideImage from '$images/art/zelda-wide-4.jpeg?enhanced';
	import MarketsPageImage from '$images/landingpage/hero/markets-page.png?enhanced';

	import FeaturesSection from '../common/FeaturesSection.svelte';

	export let sectionId: string = 'markets';
	export let areTransitionsEnabled: boolean = true;
</script>

<FeaturesSection
	{areTransitionsEnabled}
	features={[
		{
			heading: 'Index performance snapshot',
			icon: 'solar:home-2-bold',
			subheading: 'Get a quick snapshot of top index performance at a glance.'
		},
		{
			heading: 'Market moving insights',
			icon: 'solar:notebook-bookmark-bold',
			subheading: 'Track today’s biggest market winners and losers effortlessly.'
		},
		{
			heading: 'Crypto market pulse',
			icon: 'solar:users-group-two-rounded-bold',
			subheading: 'Stay updated with real-time performance of leading cryptocurrencies.'
		}
	]}
	heading="Unlock Market Insights with Tickrr"
	headingBadgeText="Your gateway to informed investing"
	images={{
		backgroundImage: {
			alt: 'Anime city',
			picture: ZeldaWideImage
		},
		mainImage: {
			alt: 'Screener',
			imgPosition: 'top-3 left-3 max-tablet:object-left tablet:top-11 tablet:left-11',
			picture: MarketsPageImage
		}
	}}
	{sectionId}
/>
