<script lang="ts">
	import { fadeInOnIntersect } from '@tickrr/ui/actions';

	import SectionHeading from '../common/SectionHeading.svelte';
	import PricingCard from '../pricing-page/PricingCard.svelte';

	export let sectionId: string = 'pricing';
	export let areTransitionsEnabled: boolean = true;
</script>

<section
	id={sectionId}
	class="space-y-8 tablet:space-y-12"
	use:fadeInOnIntersect={{
		duration: 1250,
		enabled: areTransitionsEnabled,
		translate: 'translate-y-40'
	}}
>
	<SectionHeading>
		<svelte:fragment slot="top-text">Pricing</svelte:fragment>
		<svelte:fragment slot="heading">Unbeatable pricing.</svelte:fragment>
		<svelte:fragment slot="subheading">
			Access the world's favorite investing platform at a price that won't break the bank.
		</svelte:fragment>
	</SectionHeading>
	<PricingCard />
</section>
