<script lang="ts">
	import AnimeCityImage from '$images/art/anime-city-tall.jpeg?enhanced';
	import ChartPageImage from '$images/landingpage/chart-page__full-screen.png?enhanced';

	import FeaturesSection from '../common/FeaturesSection.svelte';

	export let sectionId: string = 'charts';
	export let areTransitionsEnabled: boolean = true;
</script>

<FeaturesSection
	{areTransitionsEnabled}
	features={[
		{
			heading: 'TickrrCharts &trade;',
			icon: 'solar:chart-bold',
			subheading: 'Research and backtest your favorite stocks, cryptocurrencies, and more.'
		},
		{
			heading: 'High-quality HLOCV data',
			icon: 'solar:bolt-bold',
			subheading:
				'We source only the highest-quality data from the top providers in the industry.'
		},
		{
			heading: 'Over 20,000 Symbols',
			icon: 'solar:chat-round-money-bold',
			subheading: 'Explore comprehensive real-time market data for more than 20,000 symbols.'
		}
	]}
	heading="Next-Gen Charting"
	headingBadgeText="The best charts on the market"
	images={{
		backgroundImage: {
			alt: 'Anime city',
			picture: AnimeCityImage
		},
		mainImage: {
			alt: 'Screener',
			imgPosition: 'top-3 right-3 max-tablet:object-right tablet:right-11 tablet:top-11',
			picture: ChartPageImage
		}
	}}
	{sectionId}
/>
