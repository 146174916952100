<script lang="ts">
	import CartoonSunriseImage from '$images/art/cartoon-sunrise.webp?enhanced';
	import HomePageImage from '$images/landingpage/hero/home-page.png?enhanced';

	import FeaturesSection from '../common/FeaturesSection.svelte';

	export let sectionId: string = 'dashboard';
	export let areTransitionsEnabled: boolean = true;
</script>

<FeaturesSection
	{areTransitionsEnabled}
	features={[
		{
			heading: 'Instant intel',
			icon: 'solar:home-2-bold',
			subheading: "Get a bird's eye view of the market with our dashboard."
		},
		{
			heading: 'Breaking market news',
			icon: 'solar:notebook-bookmark-bold',
			subheading: 'Access breaking market news with a personalized news feed.'
		},
		{
			heading: 'Connect with other investors',
			icon: 'solar:users-group-two-rounded-bold',
			subheading:
				'Share your ideas and invest smarter by collaborating with the Tickrr community.'
		}
	]}
	heading="Invest Smarter with Tickrr"
	headingBadgeText="Combining simplicity with power"
	images={{
		backgroundImage: {
			alt: 'Anime city',
			picture: CartoonSunriseImage
		},
		mainImage: {
			alt: 'Screener',
			imgPosition: 'top-3 left-3 max-tablet:object-left tablet:top-11 tablet:left-11',
			picture: HomePageImage
		}
	}}
	{sectionId}
/>
