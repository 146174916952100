<script lang="ts">
	import type { Picture } from '$lib/types/Picture';

	import { fadeInOnIntersect } from '@tickrr/ui/actions';

	import type { FeaturesGridItem } from './FeaturesGrid.svelte';

	import FeaturesGrid from './FeaturesGrid.svelte';
	import SectionHeading from './SectionHeading.svelte';

	export let sectionId: string;
	export let areTransitionsEnabled: boolean;
	export let heading: string;
	export let headingBadgeText: string;
	export let features: FeaturesGridItem[];
	export let images: {
		backgroundImage: { alt: string; picture: Picture };
		mainImage: { alt: string; imgPosition: string; picture: Picture };
	};
</script>

<section
	id={sectionId}
	class="space-y-12"
	use:fadeInOnIntersect={{
		duration: 1250,
		enabled: areTransitionsEnabled,
		translate: 'translate-y-40'
	}}
>
	<div class="flex gap-12 max-laptop:flex-col laptop:items-center laptop:justify-between">
		<SectionHeading
			classes="max-w-sm"
			linkProps={{ href: '/signup', text: 'Join now, completely free' }}
		>
			<svelte:fragment slot="top-text">
				{headingBadgeText}
			</svelte:fragment>
			<svelte:fragment slot="heading">
				{heading}
			</svelte:fragment>
		</SectionHeading>

		<FeaturesGrid {features} />
	</div>

	<div
		class="relative w-full overflow-hidden border border-base rounded-container-token max-tablet:aspect-[3/4] tablet:aspect-video"
	>
		<enhanced:img
			alt={images.backgroundImage.alt}
			loading="lazy"
			src={images.backgroundImage.picture}
			class="absolute inset-0 w-full object-cover max-tablet:h-full"
		/>
		<enhanced:img
			alt={images.mainImage.alt}
			loading="lazy"
			src={images.mainImage.picture}
			class="absolute {images.mainImage
				.imgPosition} w-full object-cover rounded-container-token max-tablet:h-full"
		/>
	</div>
</section>
