<script context="module" lang="ts">
	export type FeaturesGridItem = {
		heading: string;
		icon: string;
		subheading: string;
	};
</script>

<script lang="ts">
	export let features: FeaturesGridItem[];
</script>

<div
	class="grid w-full items-stretch max-tablet:grid-cols-1 max-tablet:gap-6 tablet:grid-cols-3 tablet:gap-x-8"
>
	{#each features as f (f)}
		<div class="flex w-full flex-col">
			<span
				class="mb-6 w-fit rounded-[20px] bg-gradient-to-br from-surface-500 to-surface-700 p-[14px]"
			>
				<iconify-icon inline={true} icon={f.icon} height={32} class="text-white" />
			</span>
			<!-- eslint-disable-next-line svelte/no-at-html-tags -->
			<h3 class="mb-2 inline font-bold text-surface-50">{@html f.heading}</h3>
			<p>{f.subheading}</p>
		</div>
	{/each}
</div>
