<script lang="ts">
	import Robot3Image from '$images/art/robot-3.jpeg?enhanced';
	import ScreenerPageImage from '$images/landingpage/screener.png?enhanced';

	import FeaturesSection from '../common/FeaturesSection.svelte';

	export let sectionId: string = 'screener';
	export let areTransitionsEnabled: boolean = true;
</script>

<FeaturesSection
	{areTransitionsEnabled}
	features={[
		{
			heading: 'World-Class Screener',
			icon: 'solar:target-bold',
			subheading: 'Utilize the most powerful and user-friendly screener on the market.'
		},
		{
			heading: 'Stocks, cryptos, & more',
			icon: 'solar:dollar-minimalistic-bold',
			subheading: 'Access by-the-minute data for all your favorite asset classes.'
		},
		{
			heading: 'Chart at a glance',
			icon: 'solar:chart-bold',
			subheading: "Visualize a stock's performance all without leaving the screener page."
		}
	]}
	heading="Stay Ahead of the Market"
	headingBadgeText="Find any asset imaginable"
	images={{
		backgroundImage: {
			alt: 'Anime city',
			picture: Robot3Image
		},
		mainImage: {
			alt: 'Home page of app',
			imgPosition: 'top-3 right-3 max-tablet:object-right tablet:top-11 tablet:right-11 ',
			picture: ScreenerPageImage
		}
	}}
	{sectionId}
/>
